import authCheckMiddleware from './auth-check';

// activationチェックに結局authチェックが必要なので、authチェックもここで行う
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;

  const authResult = await authCheckMiddleware(to, from);
  if (authResult != null) return authResult;
  const { result } = await useAsyncAccountActivationCheck();
  if (!result.value) {
    // 基本的に通らないけど型通すために書いておく
    return {
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    };
  }
  if (!result.value?.isActivated) {
    return {
      path: '/account_activation_required',
      query: {
        email: result.value.email,
      },
    };
  }
});
